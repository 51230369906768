<template>
  <mf-loading-dialog :loading="loading">
    <v-container class="pb-2 mt-n4" fluid>
      <v-layout>
        <p class="ml-1 page-subtitle">Cadastrar {{ saleTypeMapped }}</p>
        <v-spacer />
      </v-layout>
      <v-card-actions class="px-0 mt-2 mb-1">
        <mf-button outlined color="error" label="Cancelar" @click="$router.push(`/franchises/${clientId}/edit/acoes`)"></mf-button>
        <v-spacer />
        <mf-button color="error" outlined class="mr-5" label="Limpar" icon="delete" :disabled="!selected.length" @click="selected = []"></mf-button>
        <mf-button color="primary" icon="settings" :label="'Configurar ' + saleTypeMapped" :disabled="!selected.length" @click="openDialog" />
      </v-card-actions>
      <v-card class="pt-4">
        <v-card-title class="mb-6">
          <v-row>
            <v-col cols="3">
              <mf-search v-model="search" class="pt-0 mt-0" hide-details single-line="" placeholder="Busca (nome do produto)" append-icon="search" outlined />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selected"
            :loading="loading"
            :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
            :options.sync="options"
            :headers="headers"
            :items="activeProductsData"
            :search="search"
            item-key="_id"
            show-select
          >
            <template v-slot:[`item.product_type`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ getProductFromType(item.product_type).name }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-icon class="mr-2" x-small :color="item.active ? 'success' : 'error'">mdi-brightness-1</v-icon>{{ item.active ? 'Ativo' : 'Inativo' }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ formatDate(item.created_at) }}
                </v-layout>
              </v-flex>
            </template>
            <template v-slot:[`item.total_mrr`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ parseCurrencyDefault(item.total_mrr) }}
                </v-layout>
              </v-flex>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <discount-dialog v-if="discountDialog" :dialog.sync="discountDialog" :client-id="clientId" :products="selected" @click="registerAction" />
    <downsell-dialog v-if="downsellDialog" :dialog.sync="downsellDialog" :client-id="clientId" :products="selected" @click="registerAction" />
    <temporary-churn-dialog
      v-if="dialogTemporaryChurn"
      :dialog.sync="dialogTemporaryChurn"
      :client-id="clientId"
      :products="selected"
      @click="registerAction"
    />
    <dialog-confirm-sale v-model="confirmSale" :validate-contract-link="false" :disable-button="disableButton" @confirm-action="saveAction" />
  </mf-loading-dialog>
</template>

<script>
import {
  QUERY_GET_FRANCHISE_AVAILABLE_PRODUCT,
  MUTATION_CREATE_DOWNSELL,
  MUTATION_CREATE_TEMPORARY_CHURN,
  MUTATION_CREATE_RETURN_TEMPORARY_CHURN,
  MUTATION_CREATE_RENEGOTIATION_MRR
} from '@/modules/franchises/graphql'
import { parseCurrency, productsInformation } from '@/mixin'
export default {
  components: {
    DialogConfirmSale: () => import('../../../../components/atomic-components/molecules/DialogConfirmSale'),
    DownsellDialog: () => import('../../../../components/atomic-components/molecules/DownsellDialog.vue'),
    DiscountDialog: () => import('../../../../components/atomic-components/molecules/DiscountDialog.vue'),
    TemporaryChurnDialog: () => import('../../../../components/atomic-components/molecules/TemporaryChurnDialog.vue')
  },
  mixins: [parseCurrency, productsInformation],
  props: {
    clientId: {
      type: String,
      default: () => ''
    },
    saleType: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    loading: false,
    dialogTemporaryChurn: false,
    downsellDialog: false,
    discountDialog: false,
    dialogAction: false,
    search: '',
    options: {},
    maxRecords: 0,
    activeProductsData: [],
    selected: [],
    dataAction: {},
    confirmSale: false,
    disableButton: false
  }),
  computed: {
    saleTypeMapped() {
      const mapSales = {
        downsell: 'Downsell',
        discount: 'Renegociação de MRR',
        return_temporary_churn: 'Retorno do Abono Temporário',
        temporary_churn: 'Abono temporário'
      }
      return mapSales[this.saleType]
    },
    mutation() {
      switch (this.saleType) {
        case 'downsell':
          return MUTATION_CREATE_DOWNSELL
        case 'discount':
          return MUTATION_CREATE_RENEGOTIATION_MRR
        case 'return_temporary_churn':
          return MUTATION_CREATE_RETURN_TEMPORARY_CHURN
        case 'temporary_churn':
          return MUTATION_CREATE_TEMPORARY_CHURN
        default:
          return ''
      }
    },
    variables() {
      switch (this.saleType) {
        case 'return_temporary_churn':
          return {
            type: 'revert_temporary_churn',
            items: this.selected.map(item => {
              return {
                sale_id: item.sale_id,
                index: item.index,
                name: item.name,
                type: item.product_type,
                quantity: item.temporary_churn.aboned_quantity,
                unit_value: item.temporary_churn.aboned_value,
                total_value: item.temporary_churn.aboned_quantity * item.temporary_churn.aboned_value
              }
            })
          }
        case 'temporary_churn':
          return {
            type: this.dataAction.sale.type,
            items: this.dataAction.sale.items,
            temporary_churn_period: this.dataAction.sale.temporary_churn_period
          }
        case 'discount':
          return {
            type: this.dataAction.sale.type,
            items: this.dataAction.sale.items.map(item => {
              return {
                mrr_renegotiation: item.mrr_renegotiation,
                sale_id: item.sale_id,
                name: item.name,
                index: item.index,
                type: item.type,
                quantity: item.quantity,
                total_value: item.total_value
              }
            })
          }
        default:
          return {
            type: this.dataAction.sale.type,
            items: this.dataAction.sale.items
          }
      }
    },
    headers() {
      return [
        {
          text: 'ID Produto',
          value: '_id',
          align: 'center'
        },
        {
          text: 'Produto',
          value: 'product_type',
          align: 'center'
        },
        {
          text: 'Status',
          value: 'active',
          align: 'center'
        },
        {
          text: 'Total MRR',
          value: 'total_mrr',
          align: 'center'
        },
        {
          text: 'Quantidade adquirida',
          value: 'slots',
          align: 'center'
        },
        {
          text: 'Data de criação',
          value: 'created_at',
          align: 'center'
        }
      ]
    }
  },
  apollo: {
    verifyIfHasNewSale: {
      query: QUERY_GET_FRANCHISE_AVAILABLE_PRODUCT,
      fetchPolicy: 'network-only',
      variables() {
        return {
          franchise_id: this.$route.params.id,
          filters: {
            active: true,
            temporary_churn: this.saleType === 'return_temporary_churn'
          },
          pagination: {
            page: this.options.page || 1,
            pageSize: this.options.itemsPerPage || 10,
            search: this.search || null,
            sort: this.options.sortBy || [],
            sortDirection: (this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))) || []
          }
        }
      },
      update({ franchiseAvailableProducts: { count, data } }) {
        this.activeProductsData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  methods: {
    formatDate(date) {
      return this.$moment(date)
        .add(3, 'h')
        .format('DD/MM/YYYY - HH:mm:ss')
    },
    registerAction(data) {
      this.dataAction = data
      switch (this.saleType) {
        case 'discount':
          this.discountDialog = false
          break
        case 'downsell':
          this.downsellDialog = false
          break
        case 'temporary_churn':
          this.dialogTemporaryChurn = false
          break
      }
      this.confirmSale = true
    },
    openDialog() {
      switch (this.saleType) {
        case 'discount':
          this.discountDialog = true
          break
        case 'downsell':
          this.downsellDialog = true
          break
        case 'temporary_churn':
          this.dialogTemporaryChurn = true
          break
        case 'return_temporary_churn':
          this.confirmSale = true
          break
      }
    },
    async saveAction({ date, contract_link, observation }) {
      this.disableButton = true
      let variables = {
        franchise_id: this.clientId,
        sale: {
          ...this.variables,
          created_at: date,
          contract_link,
          observation
        }
      }
      try {
        await this.$apollo.mutate({
          mutation: this.mutation,
          variables,
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })
        this.$alert({
          alert_message: 'Venda inserida com sucesso',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
        this.$router.push(`/franchises/${this.clientId}/edit/acoes`)
        this.disableButton = false
      } catch (err) {
        console.log(err?.message)
        this.disableButton = false
        this.$alert({
          alert_message: 'Falha ao inserir venda',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      }
    }
  }
}
</script>
